import React, { Component } from 'react';
import { t } from '../lib/t';
import cover from '../img/cover.jpg';
import logo from '../img/py.svg';
import '../css/Home.css';

class Home extends Component {
  constructor(props) {
    super(props);
    this.state = {
    };
  }

  enter() {
    this.props.parentComponent.setState({ currentPage: 'map' });
  }

  render() {
    return (
      <div className="Home" style={{ backgroundImage: `url(${cover})` }}>
        <div className="Home-mask">
        </div>
        <img className="Home-logo" src={logo} alt="" />
        <p className="Home-intro">
          {t('intro')}
          <br />
          <span className="Home-enter" onClick={this.enter.bind(this)}>{t('explore_their_answers')}</span>
        </p>
        <div className="Home-credit">
          <a href="https://www.flickr.com/photos/julien_harneis/9732660604/in/faves-161248669@N02/" target="_blank">Photo</a> by Julien Harneis
          , <a href="https://creativecommons.org/licenses/by-sa/2.0/" target="_blank">CC BY-SA 2.0</a>
        </div>
      </div>
    );
  }
}

export default Home;
