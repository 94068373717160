import { saveAs } from 'file-saver';

function serialiseDynamicData(language) {
  const fields = ['googleData', 'questions', 'categories', 'govNameToId', 'govIdToName', 'rangeLabelsPerQuestion', 'translations'];
  
  //  add default language as 
  let data = ['window.ypcOffline = true', `window.language = '${language}'`];

  fields.forEach((field) => {
    const fieldData = window[field];
    const fieldDataSerialised = JSON.stringify(fieldData);
    data.push(`window.${field} = ${fieldDataSerialised}`);
  });

  return data.join(';');
}

function download(callback) {
  const page = './offline.html';
  
  fetch(page)
    .then(function(response) {
      return response.text();
    })
    .then(function(text) {
      //  get the latest dynamic data
      const dynamicData = serialiseDynamicData(window.language);

      //  "inject" them into the static html file which has all 
      //  the other assets/resources inlined from the build
      const htmlString = text.replace('window.ypcOffline = false;', dynamicData);
      
      // //  create a new file
      const blob = new Blob([htmlString], {type: 'text/html'});
      saveAs(blob, 'download.html');
      callback();
    });
}

export default download;
