import React, { Component } from 'react';

class Circle extends Component {
  render() {
    return (
      <svg
        width="59.442764mm"
        height="42.651402mm"
        viewBox="0 0 59.442764 42.651402"
        version="1.1"
        id="circleBase"
      >
        <g
          id="layer1"
          transform="translate(149.16186,-6.5552518)"
        >
          <circle
            style={{ opacity: 0.8, fill: '#ff6c00', fillOpacity: 1, stroke: 'none', strokeWidth: 1.05123544, strokeOpacity: 1 }}
            id="path2997"
            cx="-127.78781"
            cy="27.832598"
            r="0"
          />
          <path
            id="path1155"
            style={{ fill: 'none', stroke: '#ffffff', strokeWidth: 0.35277775, strokeOpacity: 1 }}
            d="m -127.55451,27.832598 h 37.306248"
          />
          <path
            id="path1159"
            style={{ fill: '#ffffff', fillOpacity: 1, stroke: 'none', strokeWidth: 0.35277775 }}
            d="m -127.78779,27.094707 c 0.20376,0 0.37768,0.07204 0.52175,0.216122 0.14408,0.144085 0.21612,0.318005 0.21612,0.521769 0,0.203765 -0.072,0.377688 -0.21612,0.521769 -0.14407,0.144082 -0.31799,0.216122 -0.52175,0.216122 -0.20377,0 -0.37769,-0.07204 -0.5218,-0.216122 -0.14407,-0.144081 -0.21611,-0.318004 -0.21611,-0.521769 0,-0.203764 0.072,-0.377684 0.21611,-0.521769 0.14411,-0.144081 0.31803,-0.216122 0.5218,-0.216122 z"
          />
        </g>
      </svg>
    );
  }
}

export default Circle;
