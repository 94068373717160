import $ from 'jquery';

/*
	Custom imgrur upload utility based on
	https://github.com/kevva/imgur-uploader
	but for browser, not Node.
*/
const URL = 'https://api.imgur.com/3/image';

function uploadImage(buf, opts = {}) {
	const opt = Object.assign({token: 'Client-ID 34b90e75ab1c04b'}, opts);
	const token = opt.token;

	delete opt.token;

	const headers = {
    Authorization: token,
    Accept: 'application/json'
  };

  const image = buf.toString('base64');
  const type = 'base64';
  const data = Object.assign({ image, type }, opt);

  const ajaxOpts = {
    url: URL,
    type: 'POST',
    headers,
    data
 	};

	return $.ajax(ajaxOpts).then((response) => {
		return response.data;
	});
}

export default uploadImage;
