import React from 'react';

let language = window.language || 'ar';

//  DO NOT TRY TO GUES THE LANGUAGE, USE ARABIC AS DEFAULT 
//  AND ONLY SWITCH LANGUAGES IF `LANG` PARAM PRESENT IN URL
// language = navigator.languages || navigator.language || navigator.userLanguage || 'en';
// if (language.constructor === Array) {
//   language = language[0];
// }

if (/lang/.test(window.location.search)) {
  language = window.location.search.replace(/^\?lang=/, '');
}
language = language.replace(/[-_].*$/, '').toLowerCase();
if (['en', 'ar'].indexOf(language) === -1) {
  language = 'en';
}

// make sure we have a correct language stored
// if we're downloading the offline version
if (!window.offline) {
  window.language = language;
}

window.onload = function() {
  document.getElementsByTagName('BODY')[0].className = language;
}

function t(enStr, data, html) {
  const { translations } = window;
  const lang = language;
  let str = '';
  if (translations.en[enStr]) {
    enStr = translations.en[enStr];
  }
  if (lang === 'en') {
    str = enStr;
  }
  else if (translations[lang][enStr]) {
    str = translations[lang][enStr];
  }
  else {
    str = 'Translation missing: ' + enStr;
    console.log(`Translation missing for '${enStr}'`);
  }
  if (data) {
    str = str.replace(/\{([^}]+)\}/g, (x, y) => {
      return data[y];
    });
  }
  if (html) {
    return <span dangerouslySetInnerHTML={{ __html: str }} />;
  }
  return str;
}

function tn(number) {
  if (language !== 'ar') {
    return number;
  }
  var ar = ['٠', '١', '٢', '٣', '٤', '٥', '٦', '٧', '٨', '٩', '١٠'];
  return number.toString().replace(/[0-9]/g, (w) => {
    return ar[+w]
  });
}

export { t, language, tn };
