import React, { Component } from 'react';
import { t } from '../lib/t';
import download from '../lib/download';
import config from '../config';
import downloadIcon from '../img/download-small-white.svg';
import d4c from '../img/d4c.svg';
import euLogo from '../img/eu-logo.svg';
import '../css/Footer.css';

class Footer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      downloading: false,
    };
  }

  download() {
    if (this.state.downloading) {
      alert(t('download_in_progress'));
    } else {
      this.setState({ downloading: true }, () => {
        const callback = () => {
          window.setTimeout(() => {
            this.setState({ downloading: false });
          }, 1000);
        };
        download(callback);
      });
    }
  }

  enter() {
    this.props.parentComponent.setState({ currentPage: 'about' });
  }

  render() {
    return (
      <div className="Footer-container">
        <div className="Footer">
          <div className="Footer-left">
            <span onClick={this.enter.bind(this)}>{t('about')}</span>
            <a href="mailto:contact@yemenpolling.org">{t('contact')}</a>
            <a target="_blank" href="http://yemenpolling.org" rel="noopener noreferrer">{t('ypc')}</a>
          </div>
          <div className="Footer-center">
            <span className="Footer-download" onClick={this.download.bind(this)}>{this.state.downloading ? t('downloading') : t('download_site')} <img src={downloadIcon} alt="" /></span>
            <a target="_blank" rel="noopener noreferrer" href={`https://docs.google.com/spreadsheets/d/${config.spreadsheetId}`}>{t('see_all_data')}</a>
          </div>
          <div className="Footer-right">
            <p>{t('support')}</p>
            <img src={d4c} alt="" />
            <img className="Footer-eulogo" src={euLogo} alt="" />
          </div>
        </div>
        <address>{t('copyright')}</address>
      </div>
    );
  }
}

export default Footer;
