import React, { Component } from 'react';
import * as d3 from 'd3';
import $ from 'jquery';
import { tn, language } from '../lib/t';
import Circle from './Circle';
import '../css/Circles.css';

let max = 0;

class Circles extends Component {
  componentDidMount() {
    this.drawCircles();
  }

  componentDidUpdate() {
    this.drawCircles();
  }

  shouldComponentUpdate(nextProps) {
    if (
      nextProps.parentState.question !== this.props.parentState.question ||
      nextProps.question !== this.props.question ||
      nextProps.parentState.governorate !== this.props.parentState.governorate ||
      nextProps.governorate !== this.props.governorate ||
      nextProps.parentState.category !== this.props.parentState.category
    ) {
      return true;
    }
    return false;
  }

  componentWillUpdate() {
    this.removeCircles();
  }

  componentWillUmount() {
    this.removeCircles();
  }

  drawCircles() {
    const importedNode = document.getElementById('circleBase');
    const maxRadius = 21;
    d3.selectAll('.Circles-circle').each(function() {
      const id = $(this).attr('id');
      const value = $(this).attr('data-value');
      const copy = importedNode.cloneNode(true);
      this.appendChild(copy);
      const r = Math.sqrt((value * maxRadius * maxRadius) / max);
      d3.select('#' + id + ' svg circle').transition().attr('r', r).duration(1000);
    });
  }

  removeCircles() {
    $('.Circles-circle').html('');
  }

  render() {
    let { question, governorate } = this.props;
    const answers = [];
    let position = 0;
    max = 0;
    let i = 0;
    Object.keys(window.questions[question][governorate].answers).forEach((answer) => {
      i += 1;
      const { rank, count, arabic } = window.questions[question][governorate].answers[answer];
      if (count > max) {
        max = count;
      }
      if (rank !== 'dk' && rank !== 'x') {
        const element = (
          <div className="Circles-answer" key={answer} style={{ top: position }}>
            <div className="Circles-circle" data-value={count} id={`Circles-circle-${i}`}>
            </div>
            <div className="Circles-value">{tn(count)}</div>
            <div className="Circles-text">{ language === 'ar' ? arabic : answer }</div>
          </div>
        );
        answers.push(element);
        position += 80;
      }
    });

    return (
      <div className="Circles">
        <div style={{ display: 'none' }}>
          <Circle />
        </div>
        <div className="Circles-answers" style={{ height: position + 100 }}>
          {answers}
        </div>
      </div>
    );
  }
}

export default Circles;
