import React, { Component } from 'react';
import TopBar from './TopBar';
import Home from './Home';
import Map from './Map';
import About from './About';
import Footer from './Footer';
import '../css/App.css';
import '../css/App-mobile.css';

class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      currentPage: 'home',
      open: false,
    };
  }

  render() {
    //  add specific class for homepage which hides footer
    const isHomePage = this.state.currentPage === 'home';
    const appClassName = isHomePage ? "App App-home" : "App";

    return (
      <div className={appClassName}>
        <TopBar parentComponent={this} />
        { this.state.currentPage === 'home' ? <Home parentComponent={this} /> : null }
        { this.state.currentPage === 'map' ? <Map parentComponent={this} open={this.state.open} /> : null }
        { this.state.currentPage === 'about' ? <About parentComponent={this} /> : null }
        <Footer parentComponent={this} />
      </div>
    );
  }
}

export default App;
