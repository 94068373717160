import React from 'react';
import ReactDOM from 'react-dom';
import App from './components/App';
import config from './config';

// See original implementation at https://github.com/cmcenaney/ypc-data/blob/master/format_json.py#L48
function get_score_for_map(question, gov) {
  const answerRanks = []
  const answerPcts = []

  Object.keys(window.questions[question][gov].answers).forEach((answer) => {
    if (!isNaN(window.questions[question][gov].answers[answer].rank)) {
      answerRanks.push(window.questions[question][gov].answers[answer].rank);
      answerPcts.push(window.questions[question][gov].answers[answer].percentage);
    }
  });

  const maxRank = window.questions[question][gov].scale;
  const multiplier = (100 / maxRank) / 100;
  const multipliedList = [];
  answerRanks.forEach((x) => {
    multipliedList.push((x * multiplier) / 100);
  });
  
  let rank = 0;
  for (let i = 0; i < answerPcts.length; i++) {
    rank += answerPcts[i] * multipliedList[i];
  }

  return rank;
}

if (window.ypcOffline) {
  ReactDOM.render(<App />, document.getElementById('root'));
} else {
  window.gapi.load('client', function() {
    window.gapi.client
      .init({
        apiKey: config.apiKey,
        discoveryDocs: config.discoveryDocs
      })
      .then(() => {
        window.gapi.client.load('sheets', 'v4', () => {
          window.gapi.client.sheets.spreadsheets.values
            .batchGet({
              spreadsheetId: config.spreadsheetId,
              ranges: ['Data!A2:O', 'Translations!A2:C']
            })
            .then(
              response => {
                window.googleData = [];
                window.questions = {};
                window.categories = { all: [] };
                window.govNameToId = {};
                window.govIdToName = {};
                window.rangeLabelsPerQuestion = {};
                const data = response.result.valueRanges;
  
                // Data
                const nationalRespondersPerQuestion = {};
                data[0].values.forEach(function(row) {
                  window.googleData.push(row);
                  const question = row[6];
                  const gov = row[1].toLowerCase();
                  const govId = parseInt(row[0], 10);
                  const answer = row[10];
                  const cat = row[2];
                  const showOnMap = row[4] === 'y';
                  
                  if (!window.govNameToId[gov]) {
                    window.govNameToId[gov] = govId;
                  }
                  
                  if (!window.govIdToName[govId]) {
                    window.govIdToName[govId] = gov;
                  }
                  
                  if (!window.categories[cat]) {
                    window.categories[cat] = [];
                  }
                  
                  if (window.categories[cat].indexOf(question) === -1 && showOnMap) {
                    window.categories[cat].push(question);
                  }
                  
                  if (window.categories.all.indexOf(question) === -1 && showOnMap) {
                    window.categories.all.push(question);
                  }
                  
                  /*
                  question x governorate (including "national") x answer: number of responses, percentage, arabic translation and rank
                  questions: {
                    <question>: {
                      <gov>: {
                        scale,
                        answers: {
                          <answer>: {
                            count,
                            percentage,
                            rank,
                            arabic,
                          }
                        }
                      }
                    }
                  }
                  */
                  if (!window.rangeLabelsPerQuestion[question]) {
                    window.rangeLabelsPerQuestion[question] = { max: 0, min: Infinity };
                  }
                  if (parseInt(row[12], 10) < window.rangeLabelsPerQuestion[question].min) {
                    window.rangeLabelsPerQuestion[question].min = parseInt(row[12], 10);
                    window.rangeLabelsPerQuestion[question].less = { en: row[10], ar: row[11] };
                  }
                  if (parseInt(row[12], 10) > window.rangeLabelsPerQuestion[question].max) {
                    window.rangeLabelsPerQuestion[question].max = parseInt(row[12], 10);
                    window.rangeLabelsPerQuestion[question].more = { en: row[10], ar: row[11] };
                  }
                  if (!window.questions[question]) {
                    window.questions[question] = { national: { scale: parseInt(row[8]), answers: {} } };
                    nationalRespondersPerQuestion[question] = 0;
                  }
                  if (!window.questions[question][gov]) {
                    window.questions[question][gov] = { scale: parseInt(row[8]), answers: {} };
                  }
                  if (!window.questions[question][gov].answers[answer]) {
                    window.questions[question][gov].answers[answer] = {
                      rank: row[12],
                      count: parseInt(row[13]),
                      percentage: parseFloat(row[14]),
                      arabic: row[11],
                    }
                    if (!window.questions[question]['national'].answers[answer]) {
                      window.questions[question]['national'].answers[answer] = {
                        rank: row[12],
                        count: 0,
                        percentage: parseFloat(row[14]),
                        arabic: row[11],
                      }
                    }
                    window.questions[question]['national'].answers[answer].count += parseInt(row[13]);
                    nationalRespondersPerQuestion[question] += parseInt(row[13]);
                  }
  
                  // National
                  Object.keys(window.questions).forEach((question) => {
                    Object.keys(window.questions[question]['national'].answers).forEach((answer) => {
                      window.questions[question]['national'].answers[answer].percentage = window.questions[question]['national'].answers[answer].count / nationalRespondersPerQuestion[question];
                    });
                  });
                });
                
                // Calculate scores for the map
                Object.keys(window.questions).forEach((question) => {
                  Object.keys(window.questions[question]).forEach((gov) => {
                    window.questions[question][gov].score = get_score_for_map(question, gov);
                  });
                });
  
                // Translations
                window.translations = { ar: {}, en: {} };
                data[1].values.forEach(function(row) {
                  if (row[0] !== '-') {
                    window.translations.en[row[0]] = row[1];
                  }
                  window.translations.ar[row[1]] = row[2];
                });
  
                ReactDOM.render(<App />, document.getElementById('root'));
              },
              response => {
                console.log(response.result.error);
              }
            );
        });
      });
  });
}
