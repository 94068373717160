import React, { Component } from 'react';
import { language } from '../lib/t';
import '../css/TopBar.css';
import arrowIcon from '../img/arrow.svg';
import arrowUpIcon from '../img/arrowup.svg';
import logo from '../img/logo.svg';
import open from '../img/menu-open.svg';
import close from '../img/menu-close.svg';

class TopBar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      currentLanguage: language,
      showLanguageMenu: false,
      open: props.parentComponent.state.open,
    };
  }

  changeLanguage(lang) {
    window.location = window.location.href.replace(/\?lang=.*/, '') + '?lang=' + lang
  }

  toggleLanguageMenu() {
    this.setState({ showLanguageMenu: !this.state.showLanguageMenu });
  }

  componentWillUpdate(nextProps) {
    if (nextProps.parentComponent.state.open !== this.state.open) {
      this.setState({ open: !this.state.open });
    }
  }

  toggleMenu() {
    this.setState({ open: !this.state.open });
    this.props.parentComponent.setState({ open: !this.props.parentComponent.state.open });
  }

  reload() {
    window.location.reload(true);
  }

  render() {
    const languageLabel = {
      en: 'EN',
      ar: 'عربى',
    };

    return (
      <div className="TopBar">
        <div className="TopBar-content">
          { this.props.parentComponent.state.currentPage === 'map' ? <span onClick={this.toggleMenu.bind(this)} className="TopBar-link TopBar-menu mobile-only"><img src={ this.state.open ? close : open } alt="" /></span> : null }
            { this.state.showLanguageMenu ? null : <span className="TopBar-link TopBar-language" onClick={this.toggleLanguageMenu.bind(this)}><span>{languageLabel[this.state.currentLanguage]}</span> <img src={arrowIcon} alt="" /></span> }
            { this.state.showLanguageMenu && this.state.currentLanguage === 'en' ?
              <span className="TopBar-link TopBar-languagemenu">
                <span><span onClick={this.changeLanguage.bind(this, 'en')}>EN</span> <img onClick={this.toggleLanguageMenu.bind(this)} src={arrowUpIcon} alt="" /></span>
                <span onClick={this.changeLanguage.bind(this, 'ar')}>عربى</span>
              </span>
            : null }
            { this.state.showLanguageMenu && this.state.currentLanguage === 'ar' ?
              <span className="TopBar-link TopBar-languagemenu">
                <span><span onClick={this.changeLanguage.bind(this, 'ar')}>عربى</span> <img onClick={this.toggleLanguageMenu.bind(this)} src={arrowUpIcon} alt="" /></span>
                <span onClick={this.changeLanguage.bind(this, 'en')}>EN</span>
              </span>
            : null }
        </div>
        <img onClick={this.reload.bind(this)} className="TopBar-logo" src={logo} alt="" />
        <hr />
      </div>
    );
  }
}

export default TopBar;
