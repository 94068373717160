import React, { Component } from 'react';
import { t } from '../lib/t';
import '../css/About.css';

class About extends Component {
  constructor(props) {
    super(props);
    this.state = {
    };
  }

  render() {
    return (
      <div className="About">
        <p>
          {t('about_content', null, true)}
        </p>
      </div>
    );
  }
}

export default About;
